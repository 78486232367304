import React, { ReactNode } from "react";
import InternalChat from "../newComponents/internalChat/InternalChat";
import NewHeader from "./components/header/NewHeader";
import ReleaseNotes from "../newComponents/releaseNotes/ReleaseNotes";
import AccessControl from "../components/ctvoicer/accessControl/AccessControl";

import "./horizontal-layout.scss";

interface INewHorizontalLayout {
  children: ReactNode;
}

const NewHorizontalLayout: React.FC<INewHorizontalLayout> = ({ children }) => {
  return (
    <div className="horizontal-layout-ui">
      <div className="horizontal-layout-main-content">
        <div className="horizontal-layout-header">
          <NewHeader />
        </div>
        <div className="horizontal-layout-content">{children}</div>
      </div>

      <AccessControl
        allowedPermissions={["chat.agent.interno", "chat.supervisor.interno"]}
        renderNoAccess={() => null}
      >
        <div className="horizontal-layout-ui-sidebar">
          <InternalChat />
        </div>
      </AccessControl>

      <ReleaseNotes />
    </div>
  );
};

export default NewHorizontalLayout;
