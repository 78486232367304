import React, { useEffect } from "react";
import themeConfig from "../configs/themeConfig";
import classnames from "classnames";
import packageJson from "../../package.json";
import { clearAllCaches } from "../utils/clear-caches";

const FullPageLayout = ({ children, ...rest }) => {
  const [isVerify, setIsVerify] = React.useState(false);
  useEffect(() => {
    const verifyVersionAndClearCache = async () => {
      const lastVersionVerified = localStorage.getItem("lastVersionVerified");

      if (lastVersionVerified !== null) {
        if (lastVersionVerified !== packageJson.version) {
          clearAllCaches();
          localStorage.setItem("lastVersionVerified", packageJson.version);
          window.location.reload();
          return;
        }
      } else {
        localStorage.setItem("lastVersionVerified", packageJson.version);
      }
      setIsVerify(true);
    }
    verifyVersionAndClearCache()
  }, [])

  if (!isVerify) {
    return null
  }

  return (
    <div
      className={classnames(
        "full-layout wrapper bg-full-screen-image blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark,
        }
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main id="main" className="main w-100 h-100">
                {children}
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageLayout;
