export const lightenColor = (color, percentage) => {
    // If color is an array [r, g, b]
    if (Array.isArray(color)) {
      const [r, g, b] = color;
      const amount = Math.round(2.55 * percentage);
      const lightenedR = Math.min(r + amount, 255);
      const lightenedG = Math.min(g + amount, 255);
      const lightenedB = Math.min(b + amount, 255);
      return `rgb(${lightenedR}, ${lightenedG}, ${lightenedB})`;
    }
  
  }
  
  export const generateDarkColorFromString = (str = "default") => {
    let value = 0;
    for (let i = 0; i < str?.length; i++) {
      value += str.charCodeAt(i);
    }
  
    const theme = document.documentElement.getAttribute("data-theme");
    const backgroundColor = theme === "light" ? [255, 255, 255] : [56, 56, 56]; // fundo padrão
    const minContrast = theme === "light" ? 1.60 : 4.5; // Contraste mínimo baseado no tema
  
    const goldenRatio = 0.618033988749895; // Proporção áurea
    let hue = (value * goldenRatio) % 1; // Valor de matiz entre 0 e 1
    let saturation = 0.5;
    let lightness = 0.4;
  
    let rgb, contrast, tries = 0;
  
    do {
      rgb = hslToRgb(hue, saturation, lightness);
      const [r, g, b] = rgb.map(Math.round);
      contrast = contrastRatio([r, g, b], backgroundColor);
  
      // Ajustar luminosidade baseado no contraste e no tema
      if (contrast < minContrast) {
        if (theme === "dark") {
          lightness = Math.min(lightness + 0.05, 1); // Aumenta luminosidade no tema Dark
        } else {
          lightness = Math.max(lightness - 0.05, 0); // Reduz luminosidade no tema Light
        }
      }
  
      tries++;
    } while (contrast < minContrast && tries < 10);
  
    const [r, g, b] = rgb.map(Math.round);
  
    return {
      dark: `rgb(${r}, ${g}, ${b})`,
      medium: lightenColor([r, g, b], 25),
      light: lightenColor([r, g, b], 50),
    };
  };
  
  

  const luminance = (r, g, b) => {
    const [R, G, B] = [r, g, b].map(c => {
      c /= 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * R + 0.7152 * G + 0.0722 * B;
  };

  const contrastRatio = (color1, color2) => {
    const lum1 = luminance(...color1);
    const lum2 = luminance(...color2);
    const L1 = Math.max(lum1, lum2) + 0.05;
    const L2 = Math.min(lum1, lum2) + 0.05;
    return L1 / L2;
  };
  
  // Função auxiliar para converter HSL para RGB
  const hslToRgb =(h, s, l) => {
    let r, g, b;
  
    if (s === 0) {
      r = g = b = l; // Tons de cinza
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
  
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }
  
    return [r * 255, g * 255, b * 255];
  }
  